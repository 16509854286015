import { Issuers } from '~/services/issuers/issuerForms'

export const STATUS_MAP = {
  COMPLETED: 'ENVIADA',
  FAILED: 'FALLIDA',
  SENT: 'PENDIENTE',
}

export interface Invoice {
  id: number
  transactionId: number
  issuerId: Issuers
  rut: string
  type: string
  amount: number
  status: keyof typeof STATUS_MAP
  estado: string
  date: string
  createDate: Date
  rejectReason?: string
  error?: string
  data: any
}
