












import { Vue, Component } from 'vue-property-decorator'
import InvoiceList from '~/components/transactions/InvoiceList.vue'
import ModalForm from '~/components/forms/ModalForm.vue'
import { Invoice } from '~/interfaces/invoice'
import { Issuers } from '~/services/issuers/issuerForms'
import { store } from '~/store/'
import { logger } from '~/services/logger'
// 16197839-6

@Component({
  components: { InvoiceList, ModalForm },
  store,
})
export default class InvoicesPage extends Vue {
  loading = false

  modalModel = {
    inputs: [
      {
        name: 'type',
        label: 'Emisor',
        type: 'select',
        options: Object.keys(Issuers),
        errorMessage: 'Campo requerido',
        validation: {
          required: true,
        },
      },
    ],
    title: 'Crear Factura',
    ctaText: 'Crear Factura',
    id: 'modalFormId',
  }

  async mounted() {
    this.loading = true
    await this.$store.dispatch('loadInvoices')
    await this.$store.dispatch('pollInvoices')
    this.loading = false
  }

  get invoices(): Invoice[] {
    return this.$store.getters.invoices
  }

  redirectToCreateInvoice({ type }: { type: Issuers }) {
    logger.log(`router push: /invoice/${type}`)
  }
}
