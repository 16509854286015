import { GetterTree, ActionTree, MutationTree } from 'vuex'
import { RootState } from '~/store'

export const state = () => ({
  count: 0 as number,
})

export type CounterModuleState = ReturnType<typeof state>

export const getters: GetterTree<CounterModuleState, RootState> = {
  count: (state) => state.count,
}

export const mutations: MutationTree<CounterModuleState> = {
  CHANGE_COUNT: (state, newVal: number) => (state.count = newVal),
}

export const actions: ActionTree<CounterModuleState, RootState> = {
  updateCount({ commit }, newVal) {
    commit('CHANGE_COUNT', newVal)
  },
  incrementCount({ state, commit }) {
    commit('CHANGE_COUNT', state.count + 1)
  },
}
