
























import { Vue, Prop, Component } from 'vue-property-decorator'
import { Issuer } from '~/interfaces/issuer'

@Component
export default class AddonInfo extends Vue {
  @Prop({ required: true }) issuer!: Issuer
}
