






































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import CardForm from './../../CardForm.vue'
import { Product } from '~/interfaces/product'
import { FormInput } from '~/interfaces/form'

@Component({
  components: { CardForm },
})
export default class Products extends Vue {
  @Prop({
    default() {
      return [
        {
          name: 'producto',
          placeholder: 'Ej: Smartphone',
          label: 'NOMBRE',
          type: 'text',
          validation: {
            required: true,
          },
        },
        {
          name: 'cantidad',
          placeholder: '2',
          label: 'CANTIDAD',
          type: 'number',
          validation: {
            required: true,
          },
        },
        {
          name: 'valor',
          placeholder: '199',
          label: 'PRECIO',
          type: 'number',
          validation: {
            required: true,
          },
        },
      ]
    },
  })
  inputs!: FormInput[]

  @Prop({ default: true }) netValue!: boolean
  @Prop({ default: 0.840336134453782 }) netPercentage!: number

  fields = [
    { key: 'producto', label: 'Nombre', visible: true },
    { key: 'cantidad', label: 'Cantidad', visible: true },
    { key: 'netValue', label: 'Valor neto', visible: this.netValue },
    { key: 'valor', label: 'Precio final', visible: true },
    { key: 'total', label: 'Total', visible: true },
    { key: 'actions', label: 'Acciones', visible: true },
  ]

  formData = {}

  products = [] as Product[]

  @Watch('netValue', { immediate: true })
  updateContent(newValue: boolean) {
    const field = this.fields.find((field) => field.key === 'netValue')
    if (field) field.visible = newValue
  }

  get visibleColumns() {
    return this.fields.filter((field) => field.visible)
  }

  calculateNetValue(product: Product) {
    return product.valor * this.netPercentage
  }

  handleSubmit() {
    this.$emit('onSubmit', this.products)
  }

  addProduct(product: Product) {
    if (this.netValue) {
      product.montoIMP = product.valor - this.calculateNetValue(product)
      product.codigoIMP = 'IVA'
    }
    this.products.push(product)
    this.handleSubmit()
  }

  removeProduct(index: number) {
    this.products.splice(index, 1)
    this.handleSubmit()
  }
}
