






























































import { Vue, Prop, Component, Watch } from 'vue-property-decorator'
import { FormSelectOption } from '~/interfaces/form'

/**
 * Input generator component
 */
@Component({})
export default class DynamicInput extends Vue {
  /**
   * Used for input name and id attributes
   */
  @Prop({ required: true }) name!: string
  @Prop({ required: true }) label!: string
  @Prop({ required: true }) type!: string
  @Prop({ default: 1 }) step!: number
  @Prop({ default: 0 }) min!: number
  /**
   * Toggle between valid and invalid state
   */
  @Prop({ default: true }) state!: boolean
  @Prop({ default: false }) required!: boolean
  @Prop({ default: '' }) placeholder!: string
  @Prop({ default: '' }) value!: string
  @Prop({ default: false }) disabled!: boolean
  @Prop({ required: false }) defaultValue!: string
  @Prop({ required: false }) errorMessage!: string
  /**
   * Options for select type
   */
  @Prop({
    default() {
      return []
    },
  })
  options!: FormSelectOption[]

  content = this.value

  get computedError() {
    const def = 'Campo invalido'
    if (this.required && !this.content) return 'Campo requerido'
    return this.errorMessage ?? def
  }

  mounted() {
    if (this.defaultValue) {
      this.content = this.defaultValue
      this.update()
    }
  }

  @Watch('value', { immediate: true })
  updateContent(newValue: string) {
    this.content = newValue
  }

  update() {
    this.$emit('input', this.content)
  }

  reset() {
    this.content = ''
  }
}
