
































































































import { Vue, Component, Prop } from 'vue-property-decorator'
import InvoiceDetail from '~/components/transactions/InvoiceDetail.vue'
import { Invoice } from '~/interfaces/invoice'

@Component({
  components: { InvoiceDetail },
})
export default class Products extends Vue {
  @Prop({ required: true }) invoices!: Invoice[]
  @Prop({ default: false }) loading!: boolean

  filter = ''

  currentPage = 1

  perPage = 5

  selectedInvoice: null | Invoice = null

  fields = [
    { key: 'date', label: 'Fecha', sortable: true },
    { key: 'type', label: 'Tipo', sortable: true },
    { key: 'amount', label: 'Monto', sortable: true },
    { key: 'estado', label: 'Estado', sortable: true },
    { key: 'issuerId', label: 'Emisor', sortable: true },
    { key: 'actions', label: 'Acciones', sortable: false },
  ]

  get rows() {
    return this.invoices.length
  }

  selectInvoice(invoice: Invoice) {
    this.selectedInvoice = invoice
    this.$bvModal.show('invoice-modal')
  }
}
