














import { Vue, Component, Prop } from 'vue-property-decorator'
import moment from 'moment'
import CreateInvoice from '~/components/forms/invoice/CreateInvoice.vue'
import InvoiceDetail from '~/components/transactions/InvoiceDetail.vue'
import { postInvoice, createClient } from '~/services/api'
import { PayloadBuilder } from '~/services/issuers/issuerParsers'
import { logger } from '~/services/logger'
import { Issuers } from '~/services/issuers/issuerForms'
import { Invoice } from '~/interfaces/invoice'
import { store } from '~/store/'
import { formatDate } from '~/services/date'
import config from '~/config'

@Component({
  components: { CreateInvoice, InvoiceDetail },
  store,
})
export default class InvoicePageType extends Vue {
  @Prop({
    default: '',
  })
  apiToken!: string

  @Prop({
    default: config.API_URL,
  })
  apiBaseUrl!: string

  @Prop({
    required: true,
  })
  frontBaseUrl!: string

  @Prop({ default: 'nubox' }) type!: Issuers
  @Prop({ default: 100 }) transactionAmount!: number | string
  @Prop({ default: 456 }) transactionId!: number
  @Prop({ required: true }) transactionDate!: string
  @Prop({ default: 'CL' }) currency!: string

  busy = false

  async mounted() {
    this.busy = true
    await this.$store.dispatch('loadToken', {
      apiToken: this.apiToken,
    })
    createClient(this.apiBaseUrl, this.apiToken, this.frontBaseUrl)
    await this.$store.dispatch('loadIssuers')
    if (this.isConfigured) {
      if (this.transactionId) {
        await this.$store.dispatch('loadInvoice', {
          transactionId: this.transactionId,
        })
      }
      this.busy = false
    } else {
      this.showError('Emisor no configurado')
      this.$emit('not-configured')
    }
  }

  get transaction() {
    return {
      id: this.transactionId,
      currency: this.currency,
      amount: parseFloat(this.transactionAmount as string), // Prop can be a string
      createDate: formatDate(this.transactionDate),
      fechaDeVencimiento: formatDate(
        moment(this.transactionDate).add(1, 'month').toISOString()
      ),
    }
  }

  get currentIssuer() {
    return this.$store.getters.issuer(this.currentType)
  }

  get isConfigured() {
    return this.currentIssuer.config.issuerId === this.currentType
  }

  get invoice(): Invoice {
    const invoice = this.$store.getters.invoice(this.transactionId)
    if (invoice && invoice.data) {
      delete invoice.data.fechaPeriodoDesde
      delete invoice.data.fechaPeriodoHasta
      delete invoice.data.fecha
      invoice.data.createDate = formatDate(this.transactionDate)
      invoice.data.fechaDeVencimiento = formatDate(
        moment(this.transactionDate).add(1, 'month').toISOString()
      )
    }
    return invoice
  }

  title(type: string) {
    return ['BOLETA_ELECTRONICA_EXENTA', 'BOLETA_ELECTRONICA'].includes(type)
      ? 'Boleta'
      : 'Factura'
  }

  async handleSubmit(formData: any) {
    try {
      this.busy = true
      const payload = PayloadBuilder[this.currentType](formData)
      payload.transactionId = this.transaction.id
      payload.amount = this.transaction.amount
      const result = await postInvoice(this.currentType, payload)
      if (result.status !== 200) {
        if (result.status === 400) this.showError(result.data.error)
        else this.showError('Algo salio mal intentar de nuevo')
        this.$emit('submit-error')
      } else {
        this.showSuccess(
          this.title(formData.invoice?.type),
          'Puedes verificar el estado actual desde la vista de transacción el cual se actualiza automáticamente'
        )
        this.$emit('submit-success')
      }
    } catch (e) {
      logger.error('Something went wrong putting issuer')
      logger.error(e)
      this.showError('Algo salio mal intentar de nuevo')
      this.$emit('submit-error')
    }
    this.busy = false
  }

  get currentType(): Issuers {
    return this.type as Issuers
  }

  showError(msg: string) {
    this.$bvToast.hide()
    this.$bvToast.toast(msg, {
      title: 'Error de facturación',
      toaster: 'b-toaster-top-center',
      noAutoHide: true,
      solid: true,
      variant: 'danger',
    })
  }

  showSuccess(type: string, msg: string) {
    this.$bvToast.hide()
    this.$bvToast.toast(msg, {
      title: `${type} emitida con éxito`,
      toaster: 'b-toaster-top-center',
      solid: true,
      variant: 'primary',
    })
  }
}
