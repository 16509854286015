import { GetterTree, ActionTree, MutationTree } from 'vuex'
import { Issuer } from '~/interfaces/issuer'
import { getIssuerConfig, getIssuers } from '~/services/api'
import {
  issuerForms,
  Issuers,
  loadConfigIntoIssuer,
} from '~/services/issuers/issuerForms'
import { RootState } from '~/store'

export const state = () => ({
  issuers: [] as Issuer[],
})

export type IssuerssModuleState = ReturnType<typeof state>

export const getters: GetterTree<IssuerssModuleState, RootState> = {
  issuers: (state) => state.issuers,
  issuer(state) {
    return (issuerId: string) =>
      state.issuers.find((issuer) => {
        return issuer.id === issuerId
      })
  },
}

export const mutations: MutationTree<IssuerssModuleState> = {
  CHANGE_NAME: (state, { newName, index }) =>
    (state.issuers[index].name = newName),
  LOAD_ISSUERS: (state, { issuers }) => (state.issuers = issuers),
  LOAD_ISSUER: (state, { issuer }) => {
    const index = state.issuers.findIndex((item) => {
      return issuer.id === item.id
    })
    if (index !== -1) state.issuers.splice(index, 1, issuer)
    else state.issuers.push(issuer)
  },
  UPDATE_ISSUER_CONFIG: (state, { issuerId, config }) => {
    const issuer = state.issuers.find((issuer) => {
      return issuer.id === issuerId
    })
    if (issuer) {
      loadConfigIntoIssuer(issuer, config)
    }
  },
}

export const actions: ActionTree<IssuerssModuleState, RootState> = {
  async loadIssuers({ commit }) {
    const apiIssuers = await getIssuers()

    // Load the forms into the issuers state & and filter out disabled issuers
    const issuers = await Promise.all(
      apiIssuers.map(async (issuer) => {
        if (!issuer.enabled) return
        issuer.forms = issuerForms[issuer.id] || issuerForms[Issuers.default]
        const config = await getIssuerConfig(issuer.id)
        // Load saved values
        loadConfigIntoIssuer(issuer, config)
        return issuer
      })
    )

    commit('LOAD_ISSUERS', { issuers })
  },

  async loadConfig({ commit }, { issuerId }) {
    const config = await getIssuerConfig(issuerId)

    commit('UPDATE_ISSUER_CONFIG', { issuerId, config })
  },
}
