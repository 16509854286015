import { FormInput } from '~/interfaces/form'
import { Issuer, IssuerForms } from '~/interfaces/issuer'

export enum Issuers {
  default = 'default',
  nubox = 'nubox',
}

export enum FormTypes {
  invoice = 'invoice',
  products = 'products',
  integration = 'integration',
}

export type InvoiceForms = FormTypes.invoice | FormTypes.products

export const NEEDS_IVA = ['BOLETA_ELECTRONICA', 'FACTURA_ELECTRONICA']

type StandardIssuerForms = {
  [key in Issuers]: IssuerForms
}

export const issuerForms: StandardIssuerForms = {
  [Issuers.default]: {
    [FormTypes.products]: [
      {
        name: 'producto',
        placeholder: 'Ej: Smartphone',
        label: 'DESCRIPCIÓN',
        type: 'text',
        errorMessage: 'Campo requerido',
        validation: {
          required: true,
        },
      },
      {
        name: 'cantidad',
        placeholder: '2',
        label: 'CANTIDAD',
        min: 1,
        type: 'number',
        errorMessage: 'Campo requerido',
        validation: {
          required: true,
        },
      },
      {
        name: 'valor',
        placeholder: '199',
        label: 'PRECIO',
        type: 'number',
        errorMessage: 'Campo requerido',
        validation: {
          required: true,
        },
      },
    ],
    [FormTypes.invoice]: [
      {
        name: 'rutContraparte',
        placeholder: 'Ingresar RUT',
        label: 'RUT',
        type: 'number',
        validation: {
          required: true,
        },
      },
      {
        name: 'razonSocial',
        placeholder: 'Ingresar Razon Social',
        label: 'Razon Social',
        type: 'text',
        validation: {
          required: true,
        },
      },
      {
        name: 'giro',
        placeholder: 'Ingresar Giro',
        label: 'Giro',
        type: 'text',
        validation: {
          required: true,
        },
      },
      {
        name: 'comuna',
        placeholder: 'Ingresar Comuna',
        label: 'Comuna',
        type: 'text',
        validation: {
          required: true,
        },
      },
      {
        name: 'direccion',
        placeholder: 'Ingresar Direccion',
        label: 'Direccion',
        type: 'text',
        validation: {
          required: true,
        },
      },
      {
        name: 'Email',
        placeholder: 'Ingresar Email',
        label: 'Email',
        type: 'text',
        errorMessage: 'Custom message',
        validation: {
          required: true,
        },
      },
    ],
    [FormTypes.integration]: [
      {
        name: 'rutFuncionario',
        placeholder: 'Ingresar RUT',
        label: 'RUT FUNCTIONARIO',
        type: 'number',
        errorMessage: '3 characters needed',
        validation: {
          required: true,
          rut: true,
        },
      },
      {
        name: 'rutEmpresa',
        placeholder: 'Ingresar RUT',
        label: 'RUT EMPRESA',
        type: 'number',
      },
      {
        name: 'token',
        placeholder: 'Ingresar TOKEN',
        label: 'TOKEN',
        type: 'text',
        errorMessage: 'Custom message',
        validation: {
          required: true,
          minLength: {
            params: [3],
          },
        },
      },
    ],
  },
  [Issuers.nubox]: {
    [FormTypes.products]: [
      {
        name: 'producto',
        placeholder: 'Ej: Smartphone',
        label: 'NOMBRE',
        type: 'text',
        errorMessage: 'Campo requerido',
        validation: {
          required: true,
        },
      },
      {
        name: 'cantidad',
        placeholder: '2',
        label: 'CANTIDAD',
        min: 1,
        type: 'number',
        errorMessage: 'Campo requerido',
        validation: {
          required: true,
        },
      },
      {
        name: 'valor',
        placeholder: '199',
        label: 'PRECIO',
        type: 'number',
        step: 0.01,
        errorMessage: 'Campo requerido',
        validation: {
          required: true,
        },
      },
      {
        name: 'descripcion',
        placeholder: 'Ej: Smartphone',
        label: 'DESCRIPCIÓN',
        type: 'textarea',
        errorMessage: 'Campo requerido',
        validation: {
          required: true,
        },
      },
    ],
    [FormTypes.invoice]: [
      {
        name: 'rutContraparte',
        placeholder: 'Ingresar RUT',
        label: 'RUT Contraparte',
        type: 'text',
        errorMessage: 'Rut Inválido',
        validation: {
          required: true,
          rut: true,
        },
      },
      {
        name: 'razonSocialContraparte',
        placeholder: 'Ingresar Razon Social',
        label: 'Razon Social contraparte',
        type: 'text',
        errorMessage: 'Campo requerido',
        validation: {
          required: true,
        },
      },
      {
        name: 'comunaContraparte',
        placeholder: 'Ingresar Comuna',
        label: 'Comuna contraparte',
        type: 'text',
        errorMessage: 'Campo requerido',
        validation: {
          required: true,
        },
      },
      {
        name: 'codigoSucursal',
        placeholder: 'Ingresar Codigo de sucursal',
        label: 'Codigo de sucursal',
        type: 'number',
        errorMessage: 'Campo requerido',
        validation: {
          required: true,
        },
      },
      {
        name: 'direccionContraparte',
        placeholder: 'Ingresar Dirección',
        label: 'Dirección contraparte',
        type: 'text',
        errorMessage: 'Campo requerido',
        validation: {
          required: true,
        },
      },
      {
        name: 'type',
        label: 'Tipo de factura',
        type: 'select',
        options: [
          { text: 'Factura Exenta', value: 'FACTURA_ELECTRONICA_EXENTA' },
          { text: 'Factura Electrónica', value: 'FACTURA_ELECTRONICA' },
          { text: 'Boleta Electrónica', value: 'BOLETA_ELECTRONICA' },
          { text: 'Boleta Exenta', value: 'BOLETA_ELECTRONICA_EXENTA' },
        ],
        defaultValue: 'FACTURA_ELECTRONICA_EXENTA',
        errorMessage: 'Campo requerido',
        validation: {
          required: true,
        },
      },
    ],
    [FormTypes.integration]: [
      {
        name: 'employeeRut',
        placeholder: 'Ingresar RUT',
        label: 'RUT Funcionario',
        type: 'text',
        errorMessage: 'RUT inválido',
        validation: {
          required: true,
          rut: true,
        },
      },
      {
        name: 'username',
        placeholder: 'Ingresar Usuario',
        label: 'USUARIO',
        type: 'text',
        errorMessage: 'Campo requerido',
        validation: {
          required: true,
        },
      },
      {
        name: 'password',
        placeholder: 'Ingresar Contraseña',
        label: 'PASSWORD',
        type: 'password',
        errorMessage: 'Campo requerido',
        validation: {
          required: true,
        },
      },
    ],
  },
}

export function loadConfigIntoIssuer(issuer: Issuer, config: any) {
  issuer.config = config
  issuer.forms[FormTypes.integration].map((input: FormInput) => {
    if (config.userConfig) {
      for (const key in config.userConfig) {
        if (key === input.name) input.defaultValue = config.userConfig[key]
      }
    }
  })
}
