import { startCase } from 'lodash'
import { formatDate } from '../date'
import { Invoice, STATUS_MAP } from '~/interfaces/invoice'

function baseParser(invoice: Invoice) {
  invoice.date = formatDate(invoice.createDate)
  invoice.estado = STATUS_MAP[invoice.status] || invoice.status
  return invoice
}

/**
 * Place to parse the custom reponse of issuers
 */
export const ApiParser = {
  nubox(invoice: Invoice) {
    invoice = baseParser(invoice)
    if (invoice.data && invoice.data.productos) {
      // Move repeated fields common to all products
      const {
        codigoSucursal,
        comunaContraparte,
        direccionContraparte,
        fecha,
        fechaPeriodoDesde,
        fechaPeriodoHasta,
        rutContraparte,
        razonSocialContraparte,
      } = invoice.data.productos[0]
      invoice.data = {
        ...invoice.data,
        codigoSucursal,
        comunaContraparte,
        direccionContraparte,
        fecha,
        estado: invoice.estado,
        type: startCase(invoice.type.toLowerCase()),
        fechaPeriodoDesde,
        fechaPeriodoHasta,
        rutContraparte,
        razonSocialContraparte,
      }
      if (invoice.rejectReason)
        invoice.data.razonDeRechazo = invoice.rejectReason
      delete invoice.data.documentoReferenciado
    }
    return invoice
  },
  default(invoice: Invoice) {
    invoice = baseParser(invoice)
    return invoice
  },
}

/**
 * Place to format formData payload for API backend
 */
export const PayloadBuilder = {
  nubox(formData: any) {
    const { type, ...data } = formData.invoice
    data.productos = formData.products
    return {
      type,
      data,
    }
  },
  default(formData: any) {
    return formData
  },
}
