















import { chunk } from 'lodash'
import { Vue, Prop, Component } from 'vue-property-decorator'
import Addon from './Addon.vue'
import { Issuer } from '~/interfaces/issuer'
import { FormValues } from '~/interfaces/form'

@Component({
  components: { Addon },
})
export default class AddonList extends Vue {
  @Prop({ required: true }) issuers!: Issuer[]
  @Prop({ default: false }) busy!: boolean
  @Prop({ default: '' }) errorText!: string
  @Prop({ default: 2 }) columns!: number

  get inColumns() {
    return chunk(this.issuers, this.columns)
  }

  handleSubmit(issuer: Issuer, data: FormValues) {
    this.$emit('onSubmit', { ...data, issuerId: issuer.id })
  }
}
