









































// eslint-disable-next-line import/named
import { BvEvent } from 'bootstrap-vue' // eslint trips on this...
import { Vue, Prop, Component } from 'vue-property-decorator'
import FormBuilder from './FormBuilder.vue'
import { FormInput } from '~/interfaces/form'

@Component({
  components: { FormBuilder },
})
export default class ModalForm extends Vue {
  $refs!: {
    form: InstanceType<typeof FormBuilder>
  }

  @Prop({ required: true }) inputs!: FormInput[]
  @Prop({ required: true }) title!: string
  @Prop({ required: true }) id!: string
  @Prop({ default: 'Open Modal' }) ctaText!: string
  @Prop({ default: null }) errorText!: string | null
  @Prop({ default: true }) showCta!: boolean
  @Prop({ default: true }) closeOnSubmit!: boolean
  @Prop({ default: false }) busy!: boolean
  @Prop({ default: false }) resetOnSubmit!: boolean

  formData = {}

  handleSubmit() {
    this.$emit('onSubmit', this.formData)
    if (this.closeOnSubmit) {
      // Hide the modal manually
      this.$nextTick(() => {
        this.close()
      })
    }
  }

  handleOk(bvModalEvt: BvEvent) {
    bvModalEvt.preventDefault()
    this.$refs.form.triggerSubmit()
  }

  open() {
    this.$bvModal.show(this.id)
  }

  close() {
    this.$bvModal.hide(this.id)
  }
}
