import * as validationFunctions from 'vuelidate/lib/validators'

function reverseString(value: string) {
  return value.split('').reverse().join('')
}

const rut = (value: string) => {
  if (!value) return false
  const regEx = /^(\d+)\s*-\s*(\d+|k|K)$/
  value = value.toString()
  const match = value.match(regEx)
  if (!match) return false
  if (
    !(match[1].length === 8 || match[1].length === 7) ||
    match[2].length !== 1
  )
    return false
  const reversed = reverseString(match[1])
  let store = 0
  let multiply = 2
  for (const char of reversed) {
    store += parseInt(char) * multiply
    multiply++
    if (multiply > 7) multiply = 2
  }
  const verifyCode = match[2].toLowerCase() as any
  let result = 11 - (store % 11)
  if (result === 10) result = 'k' as any
  if (result === 11) result = 0
  // eslint-disable-next-line eqeqeq
  return verifyCode == result
}

const MIN_DATE = new Date('2000-01-01')
const MAX_DATE = new Date('2200-01-01')

const date = (value: string) => {
  const date = new Date(value)
  return date > MIN_DATE && date < MAX_DATE
}

export default { ...validationFunctions, rut, date }
