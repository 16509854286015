

















import { Vue, Prop, Component } from 'vue-property-decorator'
import AddonInfo from './AddonInfo.vue'
import { Issuer } from '~/interfaces/issuer'
import ModalForm from '~/components/forms/ModalForm.vue'
import { FormTypes } from '~/services/issuers/issuerForms'
import { FormValues } from '~/interfaces/form'

@Component({
  components: { ModalForm, AddonInfo },
})
export default class Addon extends Vue {
  @Prop({ required: true }) issuer!: Issuer
  @Prop({ default: false }) busy!: boolean
  @Prop({ default: null }) errorText!: string | null

  handleSubmit(data: FormValues) {
    this.$emit('onSubmit', data)
  }

  get inputs() {
    return this.issuer.forms[FormTypes.integration]
  }
}
