import { GetterTree, ActionTree, MutationTree } from 'vuex'
import { getInvoice } from '../services/api'
import { Invoice } from '~/interfaces/invoice'
import { getInvoices } from '~/services/api'
import { ApiParser } from '~/services/issuers/issuerParsers'
import { RootState } from '~/store'

export const state = () => ({
  invoices: [] as Invoice[],
})

export type InvoicessModuleState = ReturnType<typeof state>

export const getters: GetterTree<InvoicessModuleState, RootState> = {
  invoices: (state) => state.invoices,
  invoice(state) {
    return (invoiceId: number) =>
      state.invoices.find((invoice) => {
        return invoice.transactionId === invoiceId
      })
  },
}

export const mutations: MutationTree<InvoicessModuleState> = {
  LOAD_INVOICES: (state, { invoices }) => (state.invoices = invoices),
  LOAD_INVOICE: (state, { invoice }) => {
    const index = state.invoices.findIndex((item) => {
      return invoice.id === item.id
    })
    if (index !== -1) state.invoices.splice(index, 1, invoice)
    else state.invoices.push(invoice)
  },
}

export const actions: ActionTree<InvoicessModuleState, RootState> = {
  async loadInvoices({ commit }) {
    const { data: apiInvoices } = await getInvoices()

    // Load the forms into the invoices state & and filter out disabled invoices
    const invoices = apiInvoices.map((invoice: Invoice) => {
      return ApiParser[invoice.issuerId](invoice)
    })

    commit('LOAD_INVOICES', { invoices })
  },
  async loadInvoice({ commit }, { transactionId }) {
    const { data: apiInvoice, status: statusCode }: { data: Invoice, status: number } = await getInvoice(
      transactionId
    )

    const { error } = apiInvoice

    if (!error && (statusCode !== 204)) {
      // Load the forms into the invoices state & and filter out disabled invoices
      const invoice = ApiParser[apiInvoice.issuerId](apiInvoice)

      commit('LOAD_INVOICE', { invoice })
    }
  },
  pollInvoices({ getters, dispatch }) {
    const intervalObj = setInterval(() => {
      const needsPolling = getters.invoices.find(
        (invoice: Invoice) => invoice.status === 'SENT'
      )
      if (needsPolling) dispatch('loadInvoices')
      else clearInterval(intervalObj)
    }, 30000)
  },
}
