























import { chunk, startCase } from 'lodash'
import { Vue, Prop, Component } from 'vue-property-decorator'

@Component
export default class InfoCard extends Vue {
  @Prop({ required: true }) data!: { [key: string]: number | string }
  @Prop({ required: true }) title!: string
  @Prop({ required: true }) subTitle!: string
  @Prop({
    default() {
      return []
    },
  })
  fields!: { key: string; label: string }[]

  @Prop({ default: 3 }) columns!: number

  get dataColumn() {
    return chunk(this.dataValues, this.columns)
  }

  get dataValues() {
    const asArray = []
    for (const key in this.data) {
      let label = key
      if (this.fields.length > 0) {
        const currentField = this.fields.find((field) => field.key === key)
        if (currentField) label = currentField.label
        else label = this.splitWords(label)
      }
      asArray.push({
        key: label,
        value: this.data[key],
      })
    }
    return asArray
  }

  splitWords(value: string) {
    if (!value) return ''
    value = value.toString()
    return startCase(value)
  }
}
