import { GetterTree, ActionTree, MutationTree, Store } from 'vuex'

import * as counter from './counter'
import * as invoices from './invoices'
import * as issuers from './issuers'

export const state = () => ({
  userId: 2,
  apiToken: 'ASK-TOKEN-1-JAKS',
})

export type RootState = ReturnType<typeof state>

export const getters: GetterTree<RootState, RootState> = {
  userId: (state) => state.userId,
  apiToken: (state) => state.apiToken,
}

export const mutations: MutationTree<RootState> = {
  CHANGE_ID: (state, newId: number) => (state.userId = newId),
  CHANGE_TOKEN: (state, newToken: string) => (state.apiToken = newToken),
}

export const actions: ActionTree<RootState, RootState> = {
  loadUser({ commit }, { userId }) {
    localStorage.setItem('userId', userId)
    commit('CHANGE_ID', userId)
  },
  loadToken({ commit }, { apiToken }) {
    commit('CHANGE_TOKEN', apiToken)
  },
}

export const store = new Store({
  state,
  getters,
  mutations,
  actions,
  modules: {
    counter,
    issuers,
    invoices,
  },
})
