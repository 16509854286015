import Axios, {
  Method,
  AxiosRequestConfig,
  AxiosResponse,
  AxiosError,
  AxiosInstance,
} from 'axios'
import sleep from 'sleep-promise'
import { logger } from './logger'
import { Issuer } from '~/interfaces/issuer'
import config from '~/config'

const MAX_RETRIES = 5
const INITIAL_BACKOFF = 200

let axiosClient: AxiosInstance
export function createClient(baseURL?: string, apiToken?: string, frontBaseURL?: string) {
  const axios = Axios.create({
    baseURL,
  })
  // Set the AUTH headers for any request
  const localApiToken = localStorage.getItem('apiToken');
  if (!localApiToken) {
    window.location.href = `${frontBaseURL}`;
  }
  axios.interceptors.request.use(function (config) {
    config.headers.Authorization = `Bearer ${
      localApiToken
    }`

    return config
  })
  axiosClient = axios
  return axiosClient
}

function getClient() {
  if (!axiosClient) axiosClient = createClient(config.API_URL)
  return axiosClient
}

async function handleRequestError(
  err: AxiosError,
  url: string,
  method: Method,
  data?: object,
  retry: number = 1
): Promise<AxiosResponse<any>> {
  if (retry > MAX_RETRIES) {
    logger.error('Reached max HTTP request retries')
  } else if (err.response) {
    logger.error('Client received an error response (5xx, 4xx)')
    if (err.response.status === 500) {
      // Linear backoff
      await sleep(INITIAL_BACKOFF * retry)
      return request(url, method, data, retry + 1)
    }
    return err.response
  } else if (err.request) {
    logger.error('client never received a response, or request never left')
    logger.error(err)
  } else {
    logger.error('Something went wrong on an Axios HTTP request')
    logger.error(err)
  }
  throw err
}

export async function request(
  url: string,
  method: Method,
  data?: object,
  retry: number = 1
): Promise<AxiosResponse<any>> {
  const client = getClient()
  const config: AxiosRequestConfig = {
    url,
    method,
  }
  if (method.toLowerCase() !== 'get') config.data = data
  else config.params = data
  try {
    const result = await client(config)
    return result
  } catch (err) {
    return handleRequestError(err, url, method, data, retry + 1)
  }
}

export async function getIssuers(): Promise<Issuer[]> {
  const { data } = await request('/api/issuers', 'get')
  return data
}

export async function getIssuerConfig(issuerId: string) {
  const { data } = await request(`/api/issuers/${issuerId}/config`, 'get')
  return data
}

export function putIssuer(
  issuerId: string,
  payload: any
): Promise<AxiosResponse<any>> {
  return request(`/api/issuers/${issuerId}`, 'put', payload)
}

export function getInvoices() {
  return request('/api/invoices/', 'get')
}

export function getInvoice(transactionId: number) {
  return request(`/api/invoices/${transactionId}`, 'get')
}

export function postInvoice(issuerId: string, payload: any): Promise<any> {
  return request(`/api/issuers/${issuerId}/invoices`, 'post', payload)
}
