


















import { Vue, Prop, Component } from 'vue-property-decorator'

import FormBuilder from './FormBuilder.vue'
import { FormInput } from '~/interfaces/form'

@Component({
  components: { FormBuilder },
})
export default class CardForm extends Vue {
  $refs!: {
    form: InstanceType<typeof FormBuilder>
  }

  @Prop({ required: true }) inputs!: FormInput[]
  @Prop({ required: true }) title!: string
  @Prop({ default: false }) resetOnSubmit!: boolean

  formData = {}

  triggerSubmit() {
    this.$refs.form.triggerSubmit()
  }
}
