export const logger = {
  error(e: any) {
    // eslint-disable-next-line no-console
    console.error(e)
  },
  log(e: any) {
    // eslint-disable-next-line no-console
    console.log(e)
  },
}
