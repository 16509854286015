/* eslint-disable  */
import round from 'vue-round-filter'
import BootstrapVue from 'bootstrap-vue'
import Vuex from "vuex";
import * as components from './components'
// import 'bootstrap/dist/css/bootstrap.css'
// import 'bootstrap-vue/dist/bootstrap-vue.css'
import './assets/scss/export.scss'

const ComponentLibrary = {
  install(Vue, options = {}) {
    Vue.use(BootstrapVue)
    Vue.use(Vuex)

    Vue.filter('round', round)
    // components
    for (const componentName in components) {
      const component = components[componentName]
      Vue.component(componentName, component)
    }
  },
}

export default ComponentLibrary

if (typeof window !== 'undefined' && window.Vue) {
  window.Vue.use(ComponentLibrary)
  window.Vue.use(BootstrapVue)
  window.Vue.use(Vuex);
}
