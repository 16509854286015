



















import { Vue, Component, Prop } from 'vue-property-decorator'
import { Invoice } from '~/interfaces/invoice'
import InfoCard from '~/components/common/InfoCard.vue'

@Component({
  components: { InfoCard },
})
export default class Products extends Vue {
  @Prop({ required: true }) invoice!: Invoice

  fields = [
    { key: 'id', label: 'Id factura' },
    { key: 'amount', label: 'Monto' },
    { key: 'date', label: 'Fecha' },
    { key: 'status', label: 'Estado' },
    { key: 'type', label: 'Tipo' },
    { key: 'createDate', label: 'Fecha de Emisión' },
  ]

  productFields = [
    { key: 'producto', label: 'Nombre' },
    { key: 'cantidad', label: 'Cantidad' },
    { key: 'valor', label: 'Precio' },
  ]

  get removeProducts() {
    // Decosntruct without modifying original object
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { productos, ...rest } = this.invoice.data
    return rest
  }

  get title() {
    return ['BOLETA_ELECTRONICA_EXENTA', 'BOLETA_ELECTRONICA'].includes(
      this.invoice.type
    )
      ? 'Boleta'
      : 'Factura'
  }
}
