
















































import { sumBy } from 'lodash'
import { Vue, Prop, Component } from 'vue-property-decorator'
import CardForm from '../CardForm.vue'
import Products from './product/Products.vue'
import {
  issuerForms,
  Issuers,
  FormTypes,
  InvoiceForms,
  NEEDS_IVA,
} from '~/services/issuers/issuerForms'
import { Transaction } from '~/interfaces/transaction'
import InfoCard from '~/components/common/InfoCard.vue'
import { Product } from '~/interfaces/product'
import { Invoice } from '~/interfaces/invoice'

@Component({
  components: {
    Products,
    InfoCard,
    CardForm,
  },
})
export default class CreateInvoice extends Vue {
  $refs!: {
    invoiceForm: InstanceType<typeof CardForm>
  }

  @Prop({ required: true }) transaction!: Transaction
  /**
   * issuerId
   */
  @Prop() type!: Issuers
  @Prop({ default: false }) busy!: boolean

  iva = false
  formData = {
    [FormTypes.invoice]: {} as Invoice,
    [FormTypes.products]: [] as Product[],
  }

  transactionFields = [
    { key: 'id', label: 'Trxs ID' },
    { key: 'orderId', label: 'ID Orden' },
    { key: 'service', label: 'Servicio' },
    { key: 'session', label: 'Sesion' },
    { key: 'currency', label: 'Moneda' },
    { key: 'status', label: 'Estado' },
    { key: 'amount', label: 'Monto' },
    { key: 'code', label: 'Código' },
    { key: 'email', label: 'Correo' },
    { key: 'createDate', label: 'Fecha de Emisión' },
  ]

  errorText = ''

  get productsTotal() {
    if (this.formData[FormTypes.products].length === 0) return 0
    return sumBy(this.formData[FormTypes.products], function (
      product: Product
    ) {
      return product.valor * product.cantidad
    })
  }

  get showNetPercentage() {
    return this.formData[FormTypes.invoice].type === 'FACTURA_ELECTRONICA'
  }

  loadInputs(formType: InvoiceForms) {
    if (this.type) return issuerForms[this.type][formType]
    else return issuerForms[Issuers.default][formType]
  }

  handleSubmit() {
    this.$emit('onSubmit', this.formData)
  }

  loadFormData(type: InvoiceForms, formData: any) {
    this.formData[type] = formData
  }

  update(formFields: any) {
    const { type } = formFields
    if (type && NEEDS_IVA.includes(type)) this.iva = true
    else this.iva = false
    this.$emit('dataUpdated', formFields)
  }

  triggerSubmit() {
    this.$refs.invoiceForm.triggerSubmit()
    // If form validation fails formData[type] will be empty
    if (
      Object.keys(this.formData[FormTypes.invoice]).length === 0 ||
      Object.keys(this.formData[FormTypes.products]).length === 0 ||
      this.productsTotal !== this.transaction.amount
    ) {
      return
    }
    this.handleSubmit()
  }
}
