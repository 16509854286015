

































import { Vue, Component, Prop } from 'nuxt-property-decorator'
import AddonList from '~/components/addons/AddonList.vue'
import config from '~/config'
import { FormValues } from '~/interfaces/form'
import { Issuer } from '~/interfaces/issuer'
import { createClient, putIssuer } from '~/services/api'
import { logger } from '~/services/logger'
import { store } from '~/store/'

@Component({
  components: { AddonList },
  store,
})
export default class Addons extends Vue {
  @Prop({
    default: '',
  })
  apiToken!: string

  @Prop({
    default: config.API_URL,
  })
  apiBaseUrl!: string

  @Prop({
    required: true,
  })
  frontBaseUrl!: string

  busy = false
  loading = false
  error: string | null = null

  get issuers(): Issuer[] {
    return this.$store.getters.issuers
  }

  async mounted() {
    this.loading = true
    await this.$store.dispatch('loadToken', {
      apiToken: this.apiToken,
    })
    createClient(this.apiBaseUrl, this.apiToken, this.frontBaseUrl)
    await this.$store.dispatch('loadIssuers')
    this.loading = false
  }

  async handleSubmit(data: FormValues) {
    this.error = null
    this.busy = true
    const { issuerId, ...payload } = data
    try {
      const result = await putIssuer(issuerId, payload)
      // Reload issuer data
      await this.$store.dispatch('loadConfig', {
        issuerId: data.issuerId,
      })
      if (result.status !== 200) {
        if (result.status === 409) this.error = 'Credenciales inválidas'
        else if (result.status === 400) this.error = 'Solicitud invalida'
        else if (result.status === 412) this.error = result.data.error
        else this.error = 'Algo salio mal intentar de nuevo'
      } else {
        // Success case - Hide modal and show success notification
        this.$bvModal.hide(data.issuerId)
        this.$bvToast.toast(`La integración se ha cargado correctamente`, {
          title: `Solicitud exitosa`,
          toaster: 'b-toaster-top-center',
          solid: true,
          variant: 'success',
        })
      }
    } catch (e) {
      logger.error('Something went wrong putting issuer')
      logger.error(e)
      this.error = 'Algo salio mal intentar de nuevo'
    }

    this.busy = false
  }
}
