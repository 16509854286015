






















import { chunk } from 'lodash'
import { validationMixin, Validation } from 'vuelidate'
import { Vue, Prop, Component } from 'vue-property-decorator'
import { ValidPropertyDecl } from 'vue/types/options'
import DynamicInput from './DynamicInput.vue'
import validationFunctions from '~/services/validation'
import { FormInput, FormValues } from '~/interfaces/form'

Component.registerHooks(['validations'])
/**
 * Dynamic form builder that takes an array of inputs with validation rules
 */
@Component({
  components: { DynamicInput },
  mixins: [validationMixin],
})
export default class FormBuilder extends Vue {
  $refs!: {
    inputs: InstanceType<typeof DynamicInput>[]
  }

  @Prop({ required: true }) inputs!: FormInput[]
  @Prop({ default: 1 }) columns!: number
  @Prop({ default: true }) showControls!: boolean
  @Prop({ default: 'Submit' }) ctaText!: string
  @Prop({ default: false }) resetOnSubmit!: boolean

  form: FormValues = {}
  get inputColumn() {
    return chunk(this.inputs, this.columns)
  }

  validations() {
    const form: ValidPropertyDecl = {}
    for (const input of this.inputs) {
      form[input.name] = {}
      if (input.validation) {
        // TODO TypeScript this up - Realistic?
        for (const validationType in input.validation) {
          // @ts-ignore
          const validationFunction = validationFunctions[validationType]
          if (validationFunction && typeof validationFunction === 'function') {
            // @ts-ignore
            if (Array.isArray(input.validation[validationType].params)) {
              form[input.name][validationType] = validationFunction(
                // @ts-ignore
                ...input.validation[validationType].params
              )
            } else form[input.name][validationType] = validationFunction
          }
        }
      }
    }
    return { form }
  }

  validateState(name: string) {
    const { $dirty, $error } = this.$v.form[name] as Validation
    return $dirty ? !$error : null
  }

  checkValidity() {
    return this.$v.form.$anyError
  }

  handleSubmit(evt: Event) {
    evt.preventDefault()
    this.triggerSubmit()
  }

  update() {
    /**
     * Input event used for v-model two way data binding
     * @ignore
     */
    this.$emit('input', this.form)
  }

  triggerSubmit() {
    this.$v.form.$touch()
    if (this.checkValidity()) {
      return
    }
    /**
     * Submit event that passes all form data only if valid
     */
    this.$emit('onSubmit', this.form)
    if (this.resetOnSubmit) this.reset()
  }

  triggerValidate() {
    this.$v.form.$touch()
  }

  reset() {
    this.form = {}
    this.$refs.inputs.forEach((input) => input.reset())
    this.$v.form.$reset()
  }
}
